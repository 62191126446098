export interface CustomerDataModel {
  company_account: string
  company_address: string
  company_area: string
  company_authority_contact: string
  company_authority_correspondent: string
  company_authority_name: string
  company_authority_status: string
  company_bank: string
  company_certificate: string
  company_city: string
  company_holder: string
  company_name: string
  company_NPWP: string
  company_phone: string
  company_zip: string
  email: string
  group_description: string
  group_name: string
  name: string
}

export interface ProfileModel {
  main: {
    groupMetaData: CustomerDataModel
    groupName: string
  }
}

export enum ProfileActionType {
  GetCustomerData = '⌘➝App➝Profile➝GetCustomerData'
}

export interface ProfileAction {
  data: {
    groupMetaData: CustomerDataModel
    groupName: string
  }
  type: ProfileActionType.GetCustomerData
}
